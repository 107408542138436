import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea, Select } from './Input'

export const Form = styled.form`
  max-width: 650px;
  flex-basis: 75%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h2 {
    margin-bottom: 0.5rem !important;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }
  p {
    color: ${(props) => props.theme.colors.dark};
    margin: 0 auto 0.5rem !important;
    padding-right: 0 !important
    ;
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const Fieldset = styled.div`
  /* margin: -0.375rem; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  /* @media (min-width: 940px) {
    margin: -0.5rem;
  } */
`

interface Props {
  children?: React.ReactChild
  title?: string
  didFindOut?: boolean
  showMessage?: boolean
  showSuburb?: boolean
  content?: string
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  state = {
    buttonMessage: `Download Brochure`,
    email: '',
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ buttonMessage: 'Working...' })

    fetch('/.netlify/functions/send-brochure/', {
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error()
        }
        this.setState({ buttonMessage: 'Thank You!' })
        window.location.href = '/brochure.pdf'
      })
      .catch(() => {
        this.setState({ buttonMessage: 'Error - Try later!' })
        window.location.href = '/brochure.pdf'
      })
  }

  render() {
    return (
      <Form name="brochureForm" method="post" onSubmit={this.handleSubmit}>
        <Fieldset>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            required={true}
            value={this.state.email}
            onChange={this.handleChange}
          />

          <Button
            small={true}
            full={true}
            inverted={true}
            type="submit"
            radius="4px"
            style={{ marginTop: 10 }}
            onSubmit={this.handleSubmit}
          >
            {this.state.buttonMessage}
          </Button>
        </Fieldset>
      </Form>
    )
  }
}
