import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { AlternatingContent, Video } from '.'

import ContentCard from '../components/ContentCard'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { Container } from '../components/styles'
import styled from 'styled-components'
import Hero from '../components/Hero'
import BackgroundSection from '../components/BackgroundSection'
import BrochureForm from '../components/BrochureForm'

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

const Table = styled.table`
  margin: 50px auto;
  width: 80vw;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  > p {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 40vw;
  }
  @media (max-width: 800px) {
    display: none;
  }

  tbody {
    tr {
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      height: 70px;
    }
  }

  td {
    padding: 0 20px;
  }

  thead {
    padding-bottom: 30px;

    h2 {
      text-align: left;
    }

    tr td p {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true,
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 30vh;
  text-align: center;
  max-width: 600px;
  h3 {
    color: ${(props) => props.theme.colors.dark};
    text-shadow: none;
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="dark">
    <Helmet>
      <title>Pathways - Parinity Financial Professionals</title>
      <meta
        name="description"
        content="We are passionate about transparent and comprehensive financial advice. Learn about our pathways, options and parternships."
      />
    </Helmet>

    <Header style={{ fontSize: '1.25rem' }}>
      <h1>Parinity Pathways</h1>
      <br />
      <p>
        We believe it is important to think of financial advice as broader than
        investing and insurance because it is all money related decisions that
        you make that contribute to your overall financial position. We are
        passionate about transparent and comprehensive financial advice because
        we believe that is a true financial advice relationship.
      </p>
      {/* <Video
        src="https://fast.wistia.net/embed/iframe/vud7ff4i6w?dnt=1&autoplay=false"
        frameBorder="0"
        style={{ height: '33.7995vw', width: '60vw' }}
      /> */}
      <Img fluid={data.temp.childImageSharp.fluid} />
    </Header>

    <CTA
      style={{ marginTop: 50 }}
      title="Looking for a financial adviser?"
      subtitle="Submit your details here."
      button="Pre Meeting Questionnaire"
      to="https://www.parinity.com.au/mortgage-advice/apply"
      inverted={true}
    />

    <AlternatingContent>
      <h2>How we work with you</h2>
      <ContentCard
        image={data.patiosBrisbane.childImageSharp}
        title="financial foundations meeting"
        text={
          <>
            <span>
              This is a 1.5hr meeting covering all the main areas of your
              financial life. We will review what you have and what you are
              doing, and then highlight areas you may need to consider making
              changes to improve efficiencies. We will provide you example
              strategies that you can implement immediately after the meeting,
              however no specific product advice is provided. It’s your ultimate
              Financial Health Check.
            </span>

            <span>Total cost: $395 inc GST</span>
          </>
        }
        buttons={[
          {
            to: '/contact',
            text: 'Book appointment',
          },
        ]}
      />
      <ContentCard
        image={data.training.childImageSharp}
        title="project advice"
        text={
          <>
            <span>
              This fee covers the creation of your initial financial plan along
              with our meetings and related strategy discussions. It includes
              the implementation of any recommended financial products
              (insurance policies, superfunds) and will be documented via a
              formal Statement of Advice. There is no proactive ongoing advice
              provided under this arrangement, any further work will be charged
              at the Ad Hoc hourly rate.
            </span>
            <span>Project costs: $1,650 - $11,000 inc GST</span>
          </>
        }
      />
      <ContentCard
        image={data.offField.childImageSharp}
        title="parinity partnership program"
        text={
          <>
            <span>
              This is our ongoing advice service, we become your ‘Go to’ for
              anything to do with your finances, giving you options to improve
              your financial situation – then make it happen.
            </span>

            <span>
              Our partnership program is built on close, long-term relationships
              and so we only have a limited number of spaces, give us a call
              today to find out about our waiting list.
            </span>

            <span>Total cost: $550/month - $5,500/month inc GST</span>
            <BrochureForm />
          </>
        }
      />
    </AlternatingContent>

    <Table border={0} cellSpacing={0} cellPadding={0} width={614}>
      <thead>
        <tr>
          <td width={88} valign="top">
            <p>
              <span>
                <u />
                <h2>Engagement Comparison</h2>
                <u />
              </span>
            </p>
          </td>
          <td width={70} valign="top">
            <p>
              <b>
                <span>
                  Financial Health Check
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70} valign="top">
            <p>
              <b>
                <span>
                  Written Advice
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={109} valign="top">
            <p>
              <b>
                <span>
                  Implementation
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={72} valign="top">
            <p>
              <b>
                <span>
                  Tracking
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={99} valign="top">
            <p>
              <b>
                <span>
                  Accountability
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={89} valign="top">
            <p>
              <b>
                <span>
                  Adhoc Advice
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={18} valign="top">
            <p>
              <b>
                <span>
                  Fee
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width={88}>
            <p>
              <b>
                <span>
                  Financial Foundations Meeting
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={109}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={72}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={99}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={89}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={18}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                $395
                <u />
                <u />
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={88}>
            <p>
              <b>
                <span>
                  One Time Project Advice
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={109}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                Limited
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={72}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={99}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={89}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={18}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                $1,650 - $11,000
                <u />
                <u />
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={88}>
            <p>
              <b>
                <span>
                  Parinity Partnership Program
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={109}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                Full
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={72}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={99}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={89}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={18}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                $550 - $5,500 / month*ⵜ
                <u />
                <u />
              </span>
            </p>
          </td>
        </tr>

        <tr>
          <td width={88}>
            <p>
              <b>
                <span>
                  Family Office Arrangement
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={109}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                Full
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={72}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={99}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={89}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={18}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                Price on Application
                <u />
                <u />
              </span>
            </p>
          </td>
        </tr>

        {/* 
          Hourly Advice
        */}
        {/* <tr>
          <td width={88}>
            <p>
              <b>
                <span>
                  Hourly Rate
                  <u />
                  <u />
                </span>
              </b>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={70}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={109}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                ✘
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={72}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={99}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✘
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={89}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '16.0pt', fontFamily: 'Wingdings' }}>
                ✓
              </span>
              <span style={{ fontSize: '16.0pt', fontFamily: '"Avenir Book"' }}>
                <u />
                <u />
              </span>
            </p>
          </td>
          <td width={18}>
            <p align="center" style={{ textAlign: 'center' }}>
              <span>
                $275 / hr
                <u />
                <u />
              </span>
            </p>
          </td>
        </tr> */}
      </tbody>
    </Table>
    <p style={{ margin: '20px auto', textAlign: 'center', width: '50%' }}>
      *The Partnership programs includes an increasing fee structure based on
      positive results. For further details please read our Parinity Partnership
      Program brochure.
    </p>

    <p style={{ margin: '20px auto', textAlign: 'center', width: '50%' }}>
      ⵜIn certain cases, it may be necessary to transition your engagement to a Family Office Arrangement. When that time comes, we will discuss it further.
    </p>
  </Layout>
)

export const query = graphql`
  query PathwayPageQuery {
    patiosBrisbane: file(relativePath: { eq: "initial.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    temp: file(relativePath: { eq: "parinity-video-temp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "partnership.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "ongoing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
